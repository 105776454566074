/* code base style */
body{
    position: relative;
    background-color: #f0f2f5!important;
}
ul li{
    list-style: none;
}
.content{
    overflow: hidden;
}
.content-header{
    height: 56px;
}
#page-container.page-header-fixed #page-header>.content-header{
    padding-top: 0px;
    padding-bottom: 0px;
}
#sidebar .content-header p{
    font-size: 13px;
}
.sidebar-content .content-header, #page-header{
    /* background-color: #4267b2; */
}
.sidebar-content .content-header{
    height: auto;
    padding: 10px 28px;
}
.sidebar-content .title{
    text-transform: uppercase;
    padding: 5px 0px;
    display: inline-block;
    font-size: 26px;
    font-weight: 400;
    line-height: 30px;
    white-space: nowrap;
    color: #FFFFFF;
    overflow: hidden;
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    padding-bottom: 10px;
    text-align: center;
    margin: 0px;
}
.sidebar-content .content-header .content-header-section{
    padding: 0px 18px;
}
#page-header{
    /* background-color: #506dad; */
}
#page-header .info-name p{
    line-height: 16px;
    font-size: 15px;
}
#page-header .content-header-section .btn{
    color: #000;
}
#page-container.sidebar-inverse #sidebar{
    /* background: linear-gradient(to bottom,#447DF7 0%,rgba(83,60,225,1) 100%); */
    z-index: 99;
    overflow-y: auto;
    overflow-x: hidden;
}
#sidebar::-webkit-scrollbar{
    height: 8px;
    width: 8px;
}
#sidebar::-webkit-scrollbar-track {
    background: #fff;
}
#sidebar::-webkit-scrollbar-thumb{
    background: #888;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.nav-main{
    padding: 0px 10px;
}
.nav-main li{
    margin-bottom: 2px;
    user-select: none;
}
.sidebar-inverse #sidebar .nav-main a{
    color: #fff;
    font-family: Roboto,sans-serif;
    font-weight: 400;
    padding-left: 18px;
}
.sidebar-inverse #sidebar .nav-main a>i{
    color: #fff;
}
.sidebar-inverse #sidebar .nav-main a.active{
    pointer-events: none;
}
.sidebar-inverse #sidebar .nav-main a.active, .sidebar-inverse #sidebar .nav-main a:hover{
    /* background: #1357df; */
    background: #1a90ff;
    color: #fff;
    border-radius: 5px;
}
.sidebar-inverse #sidebar .nav-main a.active>i, .sidebar-inverse #sidebar .nav-main a:hover>i{
    color: #fff;
}
.sidebar-inverse #sidebar .nav-main a:focus>i{

}
#page-container.page-header-fixed #main-container{
    /* padding-top: 0px; */
    height: 100vh;
    position: relative;
    padding-top: 50px;
}
#main-container{
    
}
#main-container .content{
    min-height: calc(100% - 60px);
}
.content-side{
    padding: 0px 18px 24px 18px;
}
.block-rounded{
    border-radius: 5px!important;
}
.block.block-rounded>.block-header{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.label-title{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #202224;
    margin-bottom: 10px;
}
.input-text{
    height: 44px;
    color: #636466;
    font-size: 14px;
}
.block-title{
    font-size: 22px;
}
.btn-m {
    background-color: #4080ff;
    color: #fff;
}
.btn.btn-m:hover {
    color: #fff;
    text-decoration: none;
}

.badge-primary{
    border-color: #3f9ce8;
}
.table-text-center{
    text-align: center;
}
.breadcrumb-item:not(.active){
    color: #202224;
    text-decoration: underline;
}
.breadcrumb-item:not(.active):hover{
    color: #202224;
    text-decoration: underline;
}
.money-wallet {
    width: 100%;
    border: 1px solid #e8e9ef;
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 180px;
    padding: 15px 20px;
    margin-top: 10px;
}
.loader-m{
    display: inline-block;
    border-radius: 50%;
    border: 2px solid #f3f3f3;
    border-top-color: #ff8943;
    width: 20px;
    height: 20px;
    -webkit-animation: spinner-border 1s linear infinite;
    animation: spinner-border 1s linear infinite;
    margin: 20px auto;
}
.load-spin-box{
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
}
.btn-payment-success{
    cursor: pointer;
}
.shadow {
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
    transition: all .3s cubic-bezier(.25,.8,.25,1);
}
.header-acc-name {
    height: 30px;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #fff;
    color: #000;
    font-size: .9rem;
    padding: 5px 15px;
    border-radius: 20px;
}
.header-acc-name, .header-container-left, .header-container-right {
    display: flex;
    align-items: center;
}
.ant-pagination li a{
    display: flex!important;
    align-items: center;
    justify-content: center;
}
.table-packages tr:not(:last-child){
    border-bottom: 1px solid #ffffff;
}
.nav-main-heading{
    /* border-top: 1px solid #e4e7ed!important; */
}
.nav-main-heading span{
    color: #fff;
}
.block-content{
    padding-bottom: 20px;
}
.list-select-menu{
    margin: 0px;
    padding: 0px;
}
.list-select-menu li:not(:last-child){
    margin-bottom: 18px;
}
.list-select-menu li{
    font-weight: 600;
    color: #222222;
}
.list-select-menu li i{
    color: #4080ff;
    margin-right: 5px;
}
#page-header{
    z-index: 99!important;
}
.ant-form-item-label{
    text-align: left;
    width: 100%;
}
.nav-main li.open .nav-submenu{
    font-weight: 500;
}
.nav-main li.open>ul li a{
    padding-left: 10px;
}
.ant-modal-content{
    border-radius: 5px;
}
.ant-modal-content .ant-modal-header{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.ant-form-item{
    margin-bottom: 20px;
}
.ant-picker-suffix, .ant-picker-clear{
    display: flex;
}
.table-package .disabled input, .table-package .disabled select{
    /* background-color: #dddddd!important; */
    /* pointer-events: none; */
}
.table-white-space th, .table-white-space td{
    white-space: nowrap;
}
.table-package-mb input[name="number_page"]{
    width: 60px!important;
}
.detail-payment h5 span{
    font-size: 20px;
    border-left: 3px solid #4267b2;
    line-height: 34px;
    padding-left: 10px;
}
#sidebar .content-header{
    position: relative!important;
}
#sidebar .content-header-section{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.guides .block-content{
    font-size: 15px;
}
.guides .block-content ul li{
    list-style-type: circle;
}
.guides .block-content p{
    margin-bottom: 0px
}
.guides .block-content img {
    max-width: 100%
}
.guides .video{
    left: 0;
    width: 100%; 
    height: 0; 
    position: relative; 
    padding-bottom: 56.25%;
}
.guides .video iframe{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.footer{
    width: 100%;
    /* position: sticky; */
    /* bottom: 0px; */
    /* left: 0; */
    background: #fff;
    padding: 10px 0px;
    box-shadow: 0 -3px 5px rgba(0,0,0,0.06);
}
.content .block.block-rounded{
    min-height: 100%;
}
.content{
    padding-bottom: 30px;
}
.block-title-m{
    position: relative;
    padding: 3px 0px;
    padding-left: 10px;
    display: inline-block;
    background: rgb(26 144 255);
    border-top-right-radius: 5px;
    padding-right: 10px;
    color: #fff;
    border-bottom-right-radius: 5px;

}
.block-title-m::after{
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    left: -10px;
    background-color: #1890ff;
    top: 0px;
}
.form-scan .ant-btn.ant-btn-loading:not([disabled]){
    pointer-events: auto
}
.form-loading input, .form-loading textarea{
    pointer-events: none;
}
.spin-m .ant-spin-dot-item{
    background-color: #fff;
}
.btn-primary{
    background: #1890ff;
    border-color: #1890ff;
}
.btn-hide-sidebar{
    position: absolute;
    top: 45%;
    right: 0;
    transform: translateY(-50%);
}
.table-responsive{

}
.table-responsive::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}  
.table-responsive::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}
.table-responsive::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}
.table-responsive::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.table-vcenter td, .table-vcenter th{
    white-space: pre;
}
.ant-pagination .ant-pagination-item-link{
    display: flex;
    align-items: center;
    justify-content: center;
}
.data-loading{
    pointer-events: none;
    opacity: 0.8;
}
.filter-modal .title{
    font-size: 16px;
}
.filter-modal .filter-modal-body{
    padding: 12px;
    box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
    border-radius: 5px;
}
.btn-toggle-sidebar{

}
.btn-dual-secondary:focus{
    background-color: #fff!important;
    border-color: #fff!important
}
.btn-dual-secondary:hover{
    background-color: #d1d7e1!important;
    border-color: #d1d7e1!important
}
.register-default{
    pointer-events: none;
    opacity: 0.8;
}
.register-default .btn{
    opacity: 0.4;
}
.package-active{
    box-shadow: 0 2px 30px #d8dde5;
    transform: translateY(-3px);
    opacity: 1;
}
.ant-btn-loading-icon{
    /* display: flex!important; */
}
@media (min-width: 1200px){
    #page-container.main-content-narrow>#main-container .content, #page-container.main-content-narrow>#page-footer .content, #page-container.main-content-narrow>#page-header .content, #page-container.main-content-narrow>#page-header .content-header {
        width: 100%;
    }    
}
@media (min-width: 992px){
    #sidebar, #page-container.side-scroll #sidebar .content-header, #page-container.side-scroll #sidebar .content-side{
        width: 280px!important;
    }
    #page-container.page-header-fixed.sidebar-o #page-header, #page-container.page-header-glass.sidebar-o #page-header{
        left: 280px!important;
    }
    #page-container.sidebar-o{
        padding-left: 280px;
    }
}
.export-container{
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
    border-radius: 3px!important;
    margin-bottom: 10px;
    min-height: 100px;
}
.export-option{
    box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
    background: #f7f7f7;
    text-align: center;
    padding-bottom: 15px;
}
.export-option .title{
    justify-content: space-around;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    display: flex;
}
.export-option .title .ant-btn{
    display: flex;
    align-items: center;
}
.export-option .ant-btn:hover, .export-option .ant-btn:focus {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9;
}
.export-option .ant-btn.active{
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff;
}
#page-support .c3 span{
}
.list-file-filter+.ant-upload-list{
    max-height: 300px;
    overflow: auto;
    padding-bottom: 20px;
    /* margin-top: 5px; */
}
.list-file-filter+.ant-upload-list::-webkit-scrollbar{
    height: 6px;
    width: 6px;
}
.list-file-filter+.ant-upload-list::-webkit-scrollbar-track {
    /* background: #fff; */
    border-radius: 5px;
}
.list-file-filter+.ant-upload-list::-webkit-scrollbar-thumb{
    background: #888;
    border-radius: 5px;
}
.nav-main ul{
    padding-left: 18px;
    padding-right: 10px;
}
.nav-main a.nav-submenu::after, .nav-main a.nav-submenu::before{
    right: 10px;
}
.nav-main li.open>a.nav-submenu::before{
    opacity: 1;
}
.nav-main li.open>a.nav-submenu::after{
    opacity: 0;
}
@media (max-width: 992px){
    #sidebar .content-header{
        height: auto;
    }
}

@media (max-width: 767.98px){
    .row:not(.gutters-tiny):not(.no-gutters) {
        margin: -6px;
    }
    .row:not(.gutters-tiny):not(.no-gutters)>.col, .row:not(.gutters-tiny):not(.no-gutters)>[class*=col-] {
        padding: 6px;
    }    
    #sidebar .content-header .logo img{
        width: 80px!important;
    }
    #page-container.sidebar-inverse #sidebar{
        /* background-color: #447DF7; */
    }
}